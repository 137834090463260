import classNames from "classnames";
import dynamic from "next/dynamic";
const FormattedText = dynamic(() => import("../../formatted-text").then((mod) => mod.FormattedText));

export function ParagraphWysiwyg({paragraph, ...props}) {
  const fontSize = paragraph?.field_wysiwyg_font_size ?? "text-base";
  const color = paragraph?.field_wysiwyg_color ?? "dark";
  return (
    <div className={classNames("prose",
      {
        "text-black": color === "dark",
        "text-light": color === "light",
        "font-light": paragraph?.field_wysiwyg_light,
      },
      {
      "text-base": fontSize === "text-base",
      "md:text-lg": fontSize === "text-lg",
      "md:text-xl": fontSize === "text-xl",
      "text-xl md:text-2xl": fontSize === "text-2xl",
      "text-xl md:text-3xl md:leading-snug": fontSize === "text-3xl",
      "text-2xl md:text-4xl": fontSize === "text-4xl",
      "text-2xl md:text-5xl": fontSize === "text-5xl",
      "text-2xl md:text-6xl": fontSize === "text-6xl",
      "text-3xl md:text-7xl": fontSize === "text-7xl",
      "text-3xl md:text-8xl": fontSize === "text-8xl",
      "text-3xl md:text-9xl": fontSize === "text-9xl",
      }
    )}>
      <FormattedText processed={paragraph?.field_body?.processed}/>
    </div>
  )
}